.side-drawer {
    height: 100%;
    background: #e80b1c;
    box-shadow:  1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 40px;
    left: 0;
    width: 70%;
    max-width: 230px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    overflow-y: auto;
    padding-bottom: 40px;
}

.side-drawer.open{
    transform: translateX(0);
}

.side-drawer li {
    background: transparent;
    border: none;
}

.side-drawer .card {
    padding: 0;
    background: transparent;
    border: none;
    
}

.side-drawer .card-header {
    padding: 0;
    background: transparent;
    border: none;
    
}

.side-drawer .card-body,
.side-drawer .list-group {
    padding: 0 0;
}

.side-drawer .list-group-item {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
}

.side-drawer .menu-header-multi {
    font-weight: bold;
    color: white;
    font-size: 0.9em;
    cursor: pointer;
}

.side-drawer .nav-link {
    padding: 0;
    color: white;
    font-size: 0.9em;
}

.side-drawer .card-header:hover, .side-drawer .card-header:hover .nav-link, .side-drawer .card-header:hover .menu-header-multi{
    font-weight: bold;
}

.side-drawer .card-header:hover{
    background-color: #e05251 ;
    background-size: cover;
}

.side-drawer .menu-first-level {
    border-bottom: solid 2px #ffffff;
    padding: 0em 0em 0.5em 0em;
}

.side-drawer .menu-last-level {
    color: white;
    font-size: 0.9em;
    padding: 0em 0.5em 0em 0.5em;
}

.side-drawer .btn:focus, .side-drawer .btn:active:focus, .side-drawer .btn.active:focus{
    box-shadow:none !important;
}

/* ##Device = Mobiles */
@media screen and (max-width: 479px) {
.side-drawer {
    height: 100%;
    background: #e80b1c;
    box-shadow:  1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 40px;
    left: 0;
    width: 70%;
    max-width: 150px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    overflow-y: auto;
}

.side-drawer .nav-link {
    padding: 0;
    color: white;
    font-size: 0.8em;
}
}