.toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color:#e80b1c;
    height: 40px;
    z-index: 100;
}

.toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1em;
}

.toolbar__logo {
    margin-left: 1em;
    color: white;
    font-weight: bold;
    font-family: cursive;
}

.toolbar__logo a {
    color: white;
    text-decoration: none;
    font-size: 1em;
}

.toolbar__spacer {
    flex: 1;
}

.toolbar__navigation .toogle-button:hover .toogle-button__line{
    background-color: yellow;
}

.toolbar_navigation-items ul {
    font-weight: bold;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar_navigation-items li {
    padding: 1em;
    color: white;
    text-decoration: none;
}

.toolbar_navigation-items a {
    color: white;
    text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
    color: yellow;
}
