.App {
  height: 100%;
}

.Main {
  height: 100%;
}

.Content {
  margin-top: 56px;
  padding-left: 50px;
  margin-left: 10px;
  padding-right: 50px;
  padding-bottom: 50px;
}

.Main .AlertMessage {
  margin-top: 40px;
  padding-left: 0px;
  margin-left: 0px;
  padding-right: 0px;
}

.sticky {
  position: sticky;
  width: 100%;
  top: 0px;
  z-index: 100;
}

.Main .sticky {
  top: 40px;
}

.broswer-wrapper {
  padding: 1.5em;
}

.broswer-criteria-wrapper {
  padding: 1em;
  text-align:center;
}

.pagination-wrapper {
  position: relative;
  overflow-x:auto;
}

.pagination {
  justify-content: center;
  margin:30px;
}

.push-content-container{
  transition: 0.3s ease-out;
  margin-left: 200px;
}

.content-container{
  transition: 0.3s ease-out;
  margin-left: 0px;
}

.button-center{
  display: flex;
  justify-content: center;
}

i.icon-button, i.icon-clipboard {
  cursor: pointer;
}

i.icon-clipboard:hover{
  color: blue;
  
}

.btn-secondary { 
  background-color:#e80b1c !important;
  border-color: #e80b1c !important; 
} 

.btn-secondary:hover { 
  background-color:#C70A19 !important;
  border-color: #C70A19 !important; 
  
}

.required:after {
  content:"*";
  color: #e80b1c;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: #0000FF;
}

.link-button:hover,
.link-button:focus {
  text-decoration: underline;
  outline:none;
}

.datepicker-icon {
  background-image: url("./images/calendar_icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
}

.error {
  font-size: 0.8em !important;
  color: red !important;
  margin-bottom: 1rem !important;
  white-space: pre;
} 

.msts {
	user-select: none;
	cursor: default;
}

.alertmsg{
  display: none;
}

.msts__heading, .msts__subheading, .msts__body, .displayFlex {
  display: flex;
}

.msts__side {
	width: 50%;
}

.msts__side_controls {
	width: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.msts__list {
	padding: 0;
	margin: 0;
}

.msts__list-item {
	list-style-type: none;
	cursor: pointer;
}

.msts__list-item_disabled {
	cursor: default;
}

.msts_theme_example {
  border: 1px solid silver;
}

.msts_theme_example .msts__heading .msts__side {
	padding: 5px;
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.msts_theme_example .msts__heading .msts__side_available {
	background-color: #e80b1c;
}

.msts_theme_example .msts__heading .msts__side_selected {
	background-color: #e80b1c;
}

.msts_theme_example .msts__subheading .msts__side_filter {
	padding: 5px;
}

.msts_theme_example .msts__list {
	height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.msts_theme_example .msts__list-item {
	padding: 5px 10px;
	transition: background-color ease-in 0.1s, color ease-in 0.1s;
}

.msts_theme_example .msts__list-item:hover {
	background-color: #2980b9;
	color: #fff;
}

.msts_theme_example .msts__list-item_disabled {
	background-color: #ecf0f1;
	color: #7f8c8d;
}

.msts_theme_example .msts__list-item_disabled:hover {
	background-color: #ecf0f1;
	color: #7f8c8d;
}

.msts_theme_example .msts__list-item_highlighted {
	background-color: rgba(41, 128, 185, 0.25);
}

.msts_theme_example .msts__control {
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px 3px;
  color: #bdc3c7;
  transition: color ease 0.15s;
}

.msts_theme_example .msts__control:hover {
  color: #95a5a6;
}

.msts_theme_example .msts__control[disabled] {
  color: #ecf0f1;
}

.msts_theme_example .msts__control_select-all:after {
  content: '❯❯';
}

.msts_theme_example .msts__control_deselect-all:after {
  content: '❮❮';
}

.msts_theme_example	.msts__filter {
  position: relative;
}

.msts_theme_example	.msts__filter-input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid silver;
}

.msts_theme_example	.msts__filter-clear {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 10px;
  font-size: 1.25em;
  color: silver;
  transition: color ease 0.15s;
}

.msts_theme_example	.msts__filter-clear:after {
  content: '×';
	vertical-align: middle;
}

.msts_theme_example	.msts__filter-clear:hover {
  color: #c0392b;
}

.loading {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  /* opacity: 0.7; */
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 99;
  text-align: center;
}

.center {
  position: absolute;
  width: 100px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -35px;
}

.center_spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -5px;
  margin-top: -35px; 
}


.chartDiv {
  text-align: center;
  margin: auto;
  width: 50%;
}

.modal_header {
  background-color: #e80b1c;
  color: white;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed silver;
  background-color: silver;
  color: black;
}

.dropzone:focus {
  outline:none;
}

.expand-icon {
  background-image: url("./images/expand.png");
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
}

.expand-icon2 {
  background-image: url("./images/expand2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
}

.collapse-icon {
  background-image: url("./images/collapse.png");
  background-size: 3rem;
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
}

.collapse-icon2 {
  background-image: url("./images/collapse2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
}

.accordion-toggle {
  color: black !important;
  background-color:#fff !important;
  border-color: black !important; 
  text-align: left !important; 
  margin-bottom: 1rem;
  width: -webkit-fill-available;
} 

.accordion-toggle:hover {
  color: black !important;
  background-color:#fff !important;
  border-color: black !important; 
  text-align: left !important; 
}

.accordion-toggle-panel {
  color: #fff !important;
  background-color:#e80b1c !important;
  border-color: #e80b1c !important; 
  text-align: left !important; 
  margin-bottom: 1rem;
  width: -webkit-fill-available;
} 

.accordion-toggle-panel:hover {
  color: #fff !important;
  background-color:#C70A19 !important;
  border-color: #C70A19 !important; 
  text-align: left !important; 
}

.trnx_endor_tbl {
  width: 100%;
  border: solid;
}

.expandTable:after {
  color: #e80b1c;
  cursor: pointer;
  content: " +";
} 

.collapseTable:after {
  color: #e80b1c;
  cursor: pointer;
  content: " -";
}

.hideTable {
  display: none
}

.showTable {
  display: table-row
}

.activeTab {
  background-color: #bdc3c7
}

.contentTab {
  padding-top: 15px
}

.column {
  padding-right: 0px !important
}

.box {
  display:table; 
  width: 100%;
} 

.box .box-row { 
  display:table-row; 
} 

.box .box-cell { 
  display:table-cell; 
  width:50%; 
  padding:10px; 
}

.claim-table-header {
  background-color: #bdc3c7;
}

.content-tables {
  contain: layout;
}

.modal-dialog.modal-max {
  max-width: min-content;
}

.riskitemsTable {
  padding: 0px !important;
}

.riskitemsTable-tr {
  padding: 0px !important;
}

.riskitemsTable-td {
  padding: 2px !important;
  border-top: 0px !important;
}

.emptyrecord {
  text-align: center;
}

.container-flex {
  display: flex;
  flex-wrap: wrap;
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fff;
}

.titleH4{
  color: #e80b1c
}

.borderBottom{
  border-bottom: 2px solid #000000;
}

.thinBorderBottom{
  border-bottom: 1px solid #bdc3c7;
}

.borderTop{
  border-top: 2px solid #000000;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.table-premium-plan  {
  width: 49.5% !important;
  white-space:nowrap;
  overflow-x: auto;
}

.table-premium-plan tr  {
  height: 50px
}

.table-premium-plan .tr_duration  {
  height: 70px !important
}

.td-input-float  {
  display: flex;
  float: right;
}

.background-lightGrey {
  background-color: #D3D3D3;
}

.btn-smaller {
  font-size: 10px !important;
  padding: 0.1rem 0.3rem 0.1rem 0.3rem !important;
}

.tr-input {
  padding: 5px !important;
}

.floatLeft  {
  float: left;
}

.floatRight  {
  float: right;
}

.react-datepicker__header{
  background-color: #d4ddc5;
}

.react-datepicker__day--selected {
  border-radius: 0.8rem;
  background-color: #5d6161;
}

.react-datepicker__day:hover {
  border-radius: 0.8rem !important;
  background-color: #dbe2d0;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow
{
 border-top-color: #5d6161;
}

.react-datepicker__navigation--next{
  border-left-color: #5d6161;
}

.react-datepicker__navigation--previous{
  border-right-color: #5d6161;
}

.react-datepicker__current-month.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}

.nav-tabs .nav-link {
  cursor: pointer;
}

.view {
  margin: auto;
}

.wrapper {
  position: relative !important;
  overflow-x: auto;
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
}

.first-col {
  left: 0px;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  white-space: normal !important;
}

.second-col {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  left: 100px;
}

.whiteSpacePre {
  white-space: pre;
}

.backgroundWhite {
  background-color: white;
}

.longBenefitDesc{
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 300px;
}

.additionBenefitsDesc {
  list-style-type: none;
  padding: 0;
  white-space: pre;
}

.paddingRightZero {
  padding-right: 0px !important;
}

.selectedPlanDuration {
  border-top: solid 5px #C70A19 !important;
  border-left: solid 5px #C70A19 !important;
  border-right: solid 5px #C70A19 !important;
}
.selectedBasicPrem {
  border-left: solid 5px #C70A19 !important;
  border-right: solid 5px #C70A19 !important;
}
.selectedTotalPremium {
  /* border-bottom: solid 5px #C70A19 !important; */
  border-left: solid 5px #C70A19 !important;
  border-right: solid 5px #C70A19 !important;
}

.selectedPlan {
  border-bottom: solid 5px #C70A19 !important;
  border-left: solid 5px #C70A19 !important;
  border-right: solid 5px #C70A19 !important;
}

.selectedCol {
   border-left: solid 5px #C70A19 !important;
  border-right: solid 5px #C70A19 !important;
}

.strikethrough {
  color: #C70A19;
  text-decoration: line-through;
}

.checkbox {
  width: 16px;
  height: 16px;
  padding-right: 5px;
}

.largeError {
  font-size: 30px !important;
}

/* ##Device = Laptops, Desktops */
@media screen and (min-width: 480px) {
  .container-flex .marginLeft-5 {
    margin-left: -5%
  }
  .container-flex .marginLeft-4 {
    margin-left: -4%
  }
}

/* ##Device = Mobiles */
@media screen and (max-width: 479px) {
  .footer {
    position: relative !important; 
    width: 100%;
    bottom: 0;
    background-color: #ffffff;
  }
  
  .Content {
    margin-top: 56px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .push-content-container{
    transition: 0.3s ease-out;
    margin-left: 150px;
  }

  .chartDiv {
  width: 99%;
  }

  .report-modal-body {
    max-height:calc(100vh - 150px);
    overflow-y:auto;
    overflow-x:auto;
    white-space:pre;
    font-size: xx-small;
  }

  .btn-recalculate::before {
    content: "\a";
    white-space: pre;
  }

  .tableRow-grossPremium {
    height: 75px !important;
  }

  .tableSeparator {
    width: 1% !important;
    white-space: nowrap;
    background-color: #dee2e6;
    margin-bottom: 1rem;
  }

  .premiumPlanTable {
    font-size: small;
    border: solid #d3d3d3;
  }

  .premiumPlanSelectedTable {
    font-size: small;
    border: solid green;
  }
}

.image-overlay {
  width: 200px;
  height: 250px;
  object-fit: cover;
  margin: auto;
  padding: 40px;
  background-color: #f7f7f7;
}

.hover-overlay {
  max-width: 300px;
  height: 325px;
  object-fit: cover;
  margin: auto;
  /* Center the image horizontally */
}

.hover-overlay:hover {
  opacity: 0.8;
  border: 4px solid #333;
}

.custom-card {
  background-clip: padding-box;
  box-shadow: 0 0 10px #f7f7f7;
}

.custom-background-color {
  background-color: #f7f7f7;
 
}