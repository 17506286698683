/* ##Device = Laptops, Desktops */
@media screen and (min-width: 480px) {
  .Login {
    padding: 10px 0;
  }

  .submitBtn {
    text-align: center;
  }

  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    background: #ffffff;
  }
  
  .auth-inner {
    width: 500px;
    margin: auto;
    background: #ffffff;
    /* box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); */
    padding: 40px 25px 45px 25px;
    border-radius: 15px;
    transition: all .3s;
    min-height: 60vh;
  }

  .auth-inner.claim {
    width: initial;
    margin: auto;
    background: #ffffff;
    padding: 40px 25px 45px 25px;
    border-radius: 15px;
    transition: all .3s;
    min-height: 60vh;
  }

  .auth-top {
    width: 300px;
    margin-left: auto;
    margin-right: 0;
    padding-top: 30px;
  }

  .auth-top.claim {
    width: 300px;
    margin-left: 50px;
    margin-right: 0;
    padding-top: 30px;
  }

  .auth-bottom {
    font-size: 0.8em;
  }

  .btm-vision{
    color: #e80b1c;
    font-weight: bold;
    border-bottom: 1px solid #000000;
  }

  .company-logo {
    width: 50%;
    margin: auto;
    align-items:center;
  }

 /*  .btn--wide {
    padding-right: 3em !important;
    padding-left: 3em !important;
  } */
}

/* ##Device = Mobiles */
@media screen and (max-width: 479px) {
  .Login {
    padding: 10px 0;
  }

  .submitBtn {
    text-align: center;
  }

  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    background: #ffffff;
  }
  
  .auth-inner {
    width: 500px;
    margin: auto;
    background: #ffffff;
    /* box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); */
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }

  .company-logo {
    width: 50%;
    margin: auto;
    align-items:center;
  }

  .btn--wide {
    padding-right: 3em !important;
    padding-left: 3em !important;
  }
}